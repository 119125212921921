import React from "react"
import "../../components/animations/scroll.scss"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../../components/ImageMeta"
import { Link } from "gatsby"

export default function Prompts(props) {
  let prompts = props.prompts

  return (
    <section className="home-section prompts">
      <div className="columns">
        <div className="column is-2 is-3-widescreen"></div>
        <div className="column">
          <h2 className="h3-style has-text-centered">
            How can we help you today?
          </h2>
          <div className="columns has-text-centered is-multiline is-centered is-vcentered prompt-3-2">
            {prompts.buttons.map((buttonObj, i) => {
              let button = buttonObj.button
              return (
                <div key={button.href} className="column is-narrow">
                  <Link className="prompt-button" to={button.href}>
                    {button.buttonText}
                  </Link>
                </div>
              )
            })}
          </div>
          <div className="columns has-text-centered is-centered is-vcentered prompt-2-2-1">
            {prompts.buttons.map((buttonObj, i) => {
              let button = buttonObj.button
              if (i < 2)
                return (
                  <div key={button.href} className="column is-narrow">
                    <Link className="prompt-button" to={button.href}>
                      {button.buttonText}
                    </Link>
                  </div>
                )
            })}
          </div>
          <div className="columns has-text-centered is-centered is-vcentered prompt-2-2-1">
            {prompts.buttons.map((buttonObj, i) => {
              let button = buttonObj.button
              if (i >= 2 && i < 4)
                return (
                  <div key={button.href} className="column is-narrow">
                    <Link className="prompt-button" to={button.href}>
                      {button.buttonText}
                    </Link>
                  </div>
                )
            })}
          </div>
          <div className="columns has-text-centered is-centered is-vcentered prompt-2-2-1">
            {prompts.buttons.map((buttonObj, i) => {
              let button = buttonObj.button
              if (i === 4)
                return (
                  <div key={button.href} className="column is-narrow">
                    <Link className="prompt-button" to={button.href}>
                      {button.buttonText}
                    </Link>
                  </div>
                )
            })}
          </div>
        </div>
        <div className="column is-2 is-3-widescreen"></div>
      </div>
    </section>
  )
}

import React from "react"
import "../../components/animations/scroll.scss"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../../components/ImageMeta"

function HeadingVideo(props) {
  return (
    <section className="home-section" style={{ paddingTop: "0" }}>
      <div className="">
        <div className="columns has-text-centered">
          <div className="column">
            <h3 style={{ marginBottom: "2rem", marginTop: "4rem" }}>
              {props.post.homeHeadingVideo.heading}
            </h3>
          </div>
        </div>
        <div className="columns">
          <div className="column is-1"></div>
          <div className="column">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${props.post.homeHeadingVideo.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}
              hasWatchVideo={true}
              compId={2}
            >
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.post.homeHeadingVideo.youtubePhotoPublicId}
                data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99/v1/${props.post.homeHeadingVideo.youtubePhotoPublicId}`}
                responsive
                className="show-desktop-only lazyload"
                quality={10}
              />
              <ImageMeta
                cloudName="nuvolum"
                publicId={
                  props.post.homeHeadingVideo.youtubePhotoPublicIdMobile
                }
                data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99/v1/${props.post.homeHeadingVideo.youtubePhotoPublicIdMobile}`}
                responsive
                className="show-desktop-only-inverse lazyload"
                quality={10}
              />
            </TheaterVideo>
          </div>
          <div className="column is-1"></div>
        </div>
      </div>
    </section>
  )
}

export default HeadingVideo

import React, { Component } from "react"
import Button from "@bit/azheng.joshua-tree.button"
import PropTypes from "prop-types"
import { getMonthYearFromStrings } from "../../utils/utils"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ImageMeta from "../../components/ImageMeta"

var classNames = require("classnames")

function FacewallImage(props) {
  return (
    <>
      <div className="column is-6 fixed-facewall-item has-text-centered">
        <Link
          title="View the full testimonial"
          to={`/${props.url.toLowerCase()}/`}
        >
          <ImageMeta
            cloudName="nuvolum"
            publicId={props.imageUrl}
            data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99/v1/${props.imageUrl}`}
            width="auto"
            quality={10}
            responsive
            responsiveUseBreakpoints="true"
            className="lazyload"
          ></ImageMeta>
        </Link>
        <Link
          title="View the full testimonial"
          className="image-caption-link"
          to={`/${props.url.toLowerCase()}/`}
        >
          <div className="image-caption-wrapper">
            <h4 className="" style={{ margin: 0, marginBottom: "0" }}>
              Meet {props.reviewerName}
            </h4>
            <p>{props.mainProcedureDone}</p>
            <p
              className=""
              style={{
                margin: 0,
                marginBottom: "1.563rem",
                fontWeight: "normal"
              }}
            >
              {getMonthYearFromStrings(
                props.monthYear.year,
                props.monthYear.month
              )}
            </p>
          </div>
        </Link>
      </div>
    </>
  )
}

class FixedFacewallAOMSPDS extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    // Only allow rows of four, reviews should be sorted by most recent to least recent
    let reviewCount = this.props.reviews.length
    let nearestFourth = Math.floor(reviewCount / 4) * 4
    let reviews = this.props.reviews.slice(0, nearestFourth)
    const images = reviews.map(review => {
      return (
        <FacewallImage
          key={review.title}
          imageUrl={review.thumbnailPublicId}
          reviewerName={review.reviewerName}
          mainProcedureDone={review.mainProcedureDone}
          url={review.title}
          monthYear={review.monthYear}
          middleColumnsis={this.props.middleColumnsis}
        />
      )
    })

    return (
      <section
        className={classNames(
          "home-section fixed-facewall",
          this.props.className
        )}
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}
      >
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column">
            <h3>{this.props.heading}</h3>
            {this.props.blurb}
          </div>
          <div className="column is-2"></div>
        </div>
        <div className="columns is-multiline procedure-patient-row">
          {images}
        </div>
        <div className="columns has-text-centered">
          <div className="column">
            <Button buttonText="More Patients" href="/patient-stories/" />
          </div>
        </div>
      </section>
    )
  }
}

FixedFacewallAOMSPDS.propTypes = {
  reviews: PropTypes.array,
  middleColumnsis: PropTypes.number,
  sideColumnIs: PropTypes.number,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  heading: PropTypes.string,
  blurb: PropTypes.element
}

export default FixedFacewallAOMSPDS
